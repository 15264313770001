import React from "react";
import { Descriptions, Table, Tooltip } from "antd";
import { RightCircleFilled } from "@ant-design/icons";
import moment from "moment";

const { Item } = Descriptions;

const CorporateActionTable = ({ ORBIS }) => {
  let DTCC_LINK = (x) => {
    return `https://portal.dtcc.com/gca/gca/getCompositeList.do?archiveFlag=n&callingApp=gca&webFlow=mainWebFlow&advancedSearch=false&showSearchResults=false&actionCode=&clientId=&queryNumber=0&columnNumber=0&quickSearch=false&showSearchForm=true&searchId=-1&loadedSearchId=&gcaid=&securityid=${x}&securityname=&assetType=&issuercountry=&eventtype=&eventSubtype=&_chkPosition=on&_compositeRecordStatusList%5B0%5D.value=on&_compositeRecordStatusList%5B1%5D.value=on&_compositeRecordStatusList%5B2%5D.value=on&_compositeRecordStatusList%5B3%5D.value=on&selectAllWorkflowStatus=Y&_selectAllWorkflowStatus=on&selectAllDtcManVol=Y&_selectAllDtcManVol=on&datetype=&startdate=&plusminusind=&numberdays=&fromTime=&toTime=&chkToday=&datetype2=&startdate2=&plusminusind2=&numberdays2=&fromTime2=&toTime2=&chkToday2=&chkWatchlist=&_chkModified=on&_chkActive=on&_chkProcessedAtDTC=on&_chkPosition=on&searchName=&baseClassName=compositeSearchResults`;
  };
  const myTip = (text, record) => {
    return (
      <Tooltip
        placement="topLeft"
        title={
          <Descriptions
            size="middle"
            column={1}
            title="Detail Corporate Action"
            labelStyle={{ fontWeight: "500" }}
            contentStyle={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Item label="Symbol">
              {record.oldEntry.symbol}
              <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
              {record.newEntry.symbol}
            </Item>
            <Item label="Cusip">
              {record.oldEntry.cusip}
              <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
              {record.newEntry.cusip}
            </Item>
            <Item label="Exchange">
              {record.oldEntry.exchange}
              <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
              {record.newEntry.exchange}
            </Item>
            <Item label="Old Name">{record.oldEntry.companyName}</Item>
            <Item label="New Name">{record.newEntry.companyName}</Item>
            <Item label="Spinoff Ratio">{record.spinoffRatio}%</Item>
            <Item label="Split Factor">{record.splitFactor}</Item>
            <Item label="Dividend Amount">$USD {record.dividendAmount}</Item>
            <Item label="Gross Dividend Amount">$USD {record.grossDividendAmount}</Item>
            <Item label="DTCC Link">
              <a href={DTCC_LINK(record.oldEntry.cusip)} target="_blank">
                {record.oldEntry.cusip}
              </a>
            </Item>
          </Descriptions>
        }
        color="white"
      >
        <a href="#">{record.type}</a>
      </Tooltip>
    );
  };

  // ORBIS = ORBIS.map((obj) => ({ ...obj, NYSE: null, NASDAQ: null }));
  // console.log(ORBIS);

  // const arrNYSE = NYSE.results.map((x) => x.issue_symbol.split(" ")[0]);
  // const arrNASDAQ = NASDAQ.map((x) => x.Symbol.replace(/\/[RWU]/g, "").split(/[&/]+/)[0]);

  // ORBIS.forEach((x) => (arrNYSE.includes(x.oldEntry.Symbol) ? (x.NYSE = "YES") : (x.NYSE = "NO")));
  // ORBIS.forEach((x) => (arrNASDAQ.includes(x.oldEntry.Symbol) ? (x.NASDAQ = "YES") : (x.NASDAQ = "NO")));

  const columns = [
    {
      title: "Effect Date",
      dataIndex: "effDate",
      key: "effDate",
      align: "center",
      // width: "12%",
      render: (text) => <div>{text.split(" ")[0]}</div>,
      // sorter: (a, b) => new Date(a.effDate.split(" ")[0]) - new Date(b.effDate.split(" ")[0]),
    },
    // {
    //   title: "Ex Date",
    //   dataIndex: "exDate",
    //   key: "exDate",
    //   align: "center",
    //   // width: "12%",
    //   render: (text) => <div>{text ? text.split(" ")[0] : ""}</div>,
    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      // width: "12%",
      render: (text, record) => myTip(text, record),
    },
    {
      title: "Old Entry",
      children: [
        {
          title: "Symbol",
          dataIndex: "oldSymbol",
          key: "oldSymbol",
          align: "center",
          // width: "12%",
          render: (text, record) => <div>{record.oldEntry.symbol}</div>,
        },
        {
          title: "Exchange",
          dataIndex: "oldExchange",
          key: "oldExchange",
          align: "center",
          // width: "12%",
          render: (text, record) => <div>{record.oldEntry.exchange}</div>,
        },
        {
          title: "Name",
          dataIndex: "oldName",
          key: "oldName",
          align: "center",
          responsive: ["xl"],
          ellipsis: true,
          // width: "14%",
          render: (text, record) => <div>{record.oldEntry.companyName.substring(0, 15) + "..."}</div>,
        },
      ],
    },
    {
      title: "New Entry",
      children: [
        {
          title: "Symbol",
          dataIndex: "newSymbol",
          key: "newSymbol",
          align: "center",
          // width: "12%",
          render: (text, record) => <div>{record.newEntry.symbol}</div>,
        },
        {
          title: "Exchange",
          dataIndex: "newExchange",
          key: "newExchange",
          align: "center",
          // width: "12%",
          render: (text, record) => <div>{record.newEntry.exchange}</div>,
        },
        {
          title: "Name",
          dataIndex: "newName",
          key: "newName",
          align: "center",
          responsive: ["xl"],
          ellipsis: true,
          // width: "14%",
          render: (text, record) => <div>{record.newEntry.companyName.substring(0, 15) + "..."}</div>,
        },
      ],
    },
    // {
    //   title: "NYSE",
    //   dataIndex: "NYSE",
    //   key: "NYSE",
    //   align: "center",
    // },
    // {
    //   title: "NASDAQ",
    //   dataIndex: "NASDAQ",
    //   key: "NASDAQ",
    //   align: "center",
    // },
  ];

  const dataSource = ORBIS.sort((a, b) => b.effDate.localeCompare(a.effDate));

  // const dataSource = data?.map((x) => ({
  //   key: x.effDate + x.oldEntry.symbol,
  //   effDate: x.effDate,
  //   type: x.type,
  //   oldSymbol: x.oldEntry.symbol,
  //   oldExchange: x.oldEntry.exchange,
  //   oldName: x.oldEntry.companyName,
  //   newSymbol: x.newEntry.symbol,
  //   newExchange: x.newEntry.exchange,
  //   newName: x.newEntry.companyName,
  // }));

  return (
    <div style={{ height: "85vh" }}>
      <Table
        rowKey={(record) =>
          record.effDate +
          record.type +
          record.newEntry.symbol +
          // record.newEntry.exchange +
          // record.newEntry.companyName +
          // record.newEntry.cusip +
          // record.newEntry.isin +
          record.oldEntry.symbol +
          // record.oldEntry.exchange +
          // record.oldEntry.companyName +
          // record.oldEntry.cusip +
          // record.oldEntry.isin +
          record.grossDividendAmount +
          record.splitFactor +
          record.spinoffRatio
        }
        size="middle"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          position: ["bottomRight"],
          defaultPageSize: 100,
        }}
      />
    </div>
  );
};

export default CorporateActionTable;
