import React, { useState } from "react";
import {
  Row,
  Col,
  Switch,
  Typography,
  Checkbox,
  Divider,
  Input,
  DatePicker,
  Button,
  Empty,
  message,
  Radio,
} from "antd";
import { SearchOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getCorporateAction, getCorporateActionTypes } from "../api/CorporateActionAPI";
import { getNYSECorporateAction } from "../api/NYSE_CorporateActionAPI";
import { getNASDAQCorporateAction } from "../api/NASDAQ_CorporateAction";
import CorporateActionTable from "./CorporateActionTable";
import NYSE_CorporateActionTable from "./NYSE_CorporateActionTable";
import NASDAQ_CorporateActionTable from "./NASDAQ_CorporateActionTable";
import csvDownload from "json-to-csv-export";

const CorporateAction = () => {
  const [checkedList, setCheckedList] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [date, setDate] = useState([]);

  const [NYSE_coQueryData, setNYSE_coQueryData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [source, setSource] = useState("VIEWTRADE");
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  // Corporate Action Types
  const coTypes = useQuery({
    queryKey: ["coTypes"],
    queryFn: getCorporateActionTypes,
    staleTime: 60 * 60 * 1000,
  });

  // Orbis Corporate Actions
  const coQuery = useMutation({
    mutationFn: getCorporateAction,
    staleTime: 5000,
    // onSuccess: (data, variables, context) => {
    //   setCoData(data);
    // },
  });

  // NYSE Corporate Actions
  const NYSE_coQuery = useMutation({
    mutationFn: getNYSECorporateAction,
    staleTime: 3000,
    onSuccess: (data, variables, context) => {
      if (variables.symbol.length == 0) {
        setNYSE_coQueryData(data);
      } else {
        let symbolArr = variables.symbol.replace(/\s/g, "").split(","); // symbols need to be filtered
        let filteredData =
          variables.symbol.length == 0
            ? data
            : data.results.filter((obj) => symbolArr.includes(obj.issue_symbol.split(" ")[0])); // Filtered data
        setNYSE_coQueryData({ ...data, count: filteredData.length, results: filteredData }); // set filtered data
      }
    },
  });

  // NASDAQ Corporate Actions

  const NASDAQ_coQuery = useQuery({
    queryKey: ["NASDAQ_coQuery"],
    queryFn: getNASDAQCorporateAction,
    refetchOnWindowFocus: false,
    staleTime: 5000,
    enabled: false,
    select: (data) => {
      let symbolArr = symbol.replace(/\s/g, "").split(",");
      const dateFilter = data.filter((item) => {
        const itemDate = new Date(item.Date);
        return itemDate >= new Date(date[0]) && itemDate <= new Date(date[1]);
      });

      const regex = /\/[RWU]/g;

      const symbolFilter =
        symbol.length == 0
          ? dateFilter
          : dateFilter.filter((obj) => symbolArr.includes(obj.Symbol.replace(regex, "").split(/[&/]+/)[0]));
      return symbolFilter;
    },
  });

  const checkBoxHandler = (list) => {
    setCheckedList(list);
  };

  const switchHandler = (checked, event) => {
    setCheckedList(checked ? coTypes.data : []);
  };

  const buttonHandler = () => {
    if (source == "VIEWTRADE") {
      if (date.length && checkedList.length) {
        const queryData = {
          symbol: symbol,
          dateFrom: date[0],
          dateTo: date[1],
          type: checkedList.join(","),
          usePayDate: false,
        };
        coQuery.mutate(queryData);
      } else {
        messageApi.open({
          type: "warning",
          content: "Please input date range and types",
          duration: 3,
        });
      }
    }

    if (source == "NYSE") {
      setNYSE_coQueryData(null);
      if (date.length) {
        const queryData = {
          symbol: symbol,
          dateFrom: date[0],
          dateTo: date[1],
          type: "TenderOffer,PrivatelyHeld,Acquisition,Split,SymbolChange,NameChange,Deletion,Addition,Suspension,Merger,ExchangeChange,CusipChange,UnitSplit,Conversion,Spinoff,Other",
          usePayDate: false,
        };
        const NYSEqueryData = {
          symbol: symbol,
          dateFrom: date[0],
          dateTo: date[1],
          pageSize: 50,
          page: 1,
        };
        coQuery.mutate(queryData);
        NYSE_coQuery.mutate(NYSEqueryData);
      } else {
        messageApi.open({
          type: "warning",
          content: "Please input date range and types",
          duration: 3,
        });
      }
    }

    if (source == "NASDAQ") {
      if (date.length) {
        const queryData = {
          symbol: symbol,
          dateFrom: date[0],
          dateTo: date[1],
          type: "TenderOffer,PrivatelyHeld,Acquisition,Split,SymbolChange,NameChange,Deletion,Addition,Suspension,Merger,ExchangeChange,CusipChange,UnitSplit,Conversion,Spinoff,Other",
          usePayDate: false,
        };
        coQuery.mutate(queryData);
        NASDAQ_coQuery.refetch();
      } else {
        messageApi.open({
          type: "warning",
          content: "Please input date range and types",
          duration: 3,
        });
      }
    }
  };

  const downloadHandler = () => {
    function flattenObj(obj, param) {
      let newObj = {};
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          newObj = { ...newObj, ...flattenObj(obj[key], key + ".") };
        } else {
          newObj[param + key] = obj[key];
        }
      }
      return newObj;
    }

    function runObjArr(objArr) {
      let newArr = [];
      for (const i in objArr) {
        newArr.push(flattenObj(objArr[i], ""));
      }
      return newArr;
    }

    const dataToConvert = {
      data: runObjArr(coQuery.data),
      filename: "corporate_action",
      delimiter: ",",
    };

    csvDownload(dataToConvert);
  };

  const sourceSwitchHandler = (value) => {
    setSource(value);
    if (value != "VIEWTRADE") {
      setSwitchDisabled(true);
      setCheckboxDisabled(true);
    } else {
      setSwitchDisabled(false);
      setCheckboxDisabled(false);
    }

    // reset query
    coQuery.reset();
    NYSE_coQuery.reset();
    NASDAQ_coQuery.remove();

    // reset NYSE Filtered Data
    setNYSE_coQueryData(null);
  };

  console.log("coQyery: ", coQuery.data);
  console.log("NYSE_coQuery: ", NYSE_coQueryData);
  console.log("NASDAQ_coQuery: ", NASDAQ_coQuery.data);

  return (
    <div>
      {contextHolder}
      <Row gutter={[15, 15]} style={{ flexDirection: "row-reverse" }}>
        <Col order={2} xs={24} sm={24} lg={24} xl={18} xxl={18}>
          <div className="block">
            <Typography.Title level={3} style={{ marginLeft: "15px" }}>
              Corporate Actions
            </Typography.Title>
            <Divider style={{ borderColor: "darkblue", marginTop: "-5px" }} />

            {!coQuery?.data && NYSE_coQueryData != [] && !NASDAQ_coQuery?.data ? (
              <Empty
                style={{ height: "100vh" }}
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              />
            ) : (
              <></>
            )}

            {/* {!coQuery?.data ? (
              <Empty
                style={{ height: "75vh" }}
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              />
            ) : (
              <>
                <CorporateActionTable coData={coData} />
              </>
            )} */}

            {source == "VIEWTRADE" && coQuery?.data ? <CorporateActionTable ORBIS={coQuery?.data} /> : <></>}
            {source == "NYSE" && NYSE_coQueryData ? (
              <NYSE_CorporateActionTable ORBIS={coQuery?.data} NYSE={NYSE_coQueryData} />
            ) : (
              <></>
            )}
            {source == "NASDAQ" && NASDAQ_coQuery?.data ? (
              <NASDAQ_CorporateActionTable ORBIS={coQuery?.data} NASDAQ={NASDAQ_coQuery?.data} />
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col order={1} xs={24} sm={24} lg={24} xl={6} xxl={6}>
          <div className="block">
            <div style={{ marginTop: "50px" }} />
            <Divider plain orientation="left" style={{ color: "darkblue", borderColor: "darkblue" }}>
              Source
            </Divider>
            <div
              style={{
                textAlign: "center",
                margin: "0px 0px 35px",
              }}
            >
              <Radio.Group
                defaultValue={source}
                buttonStyle="solid"
                onChange={(e) => sourceSwitchHandler(e.target.value)}
              >
                <Radio.Button value="VIEWTRADE">VIEWTRADE</Radio.Button>
                <Radio.Button value="NYSE">NYSE</Radio.Button>
                <Radio.Button value="NASDAQ">NASDAQ</Radio.Button>
              </Radio.Group>
            </div>
            <Divider plain orientation="left" style={{ color: "darkblue", borderColor: "darkblue" }}>
              Symbol
            </Divider>
            <div style={{ margin: "0px 0px 35px" }}>
              <Input
                size="middle"
                placeholder="Symbol"
                value={symbol}
                onChange={(e) => setSymbol(e.target.value.toUpperCase())}
                style={{ margin: "-10px 0px 5px" }}
              />
              <Typography.Text type="secondary">*Empty for all symbols</Typography.Text>
            </div>

            <Divider plain orientation="left" style={{ color: "darkblue", borderColor: "darkblue" }}>
              Date Range
            </Divider>
            <DatePicker.RangePicker
              size="middle"
              style={{ width: "100%", margin: "-10px 0 25px" }}
              onChange={(date, dateString) => {
                setDate(date.map((x) => moment(x.$d).format("MM/DD/YYYY")));
                // setDate(date.map((x) => format(x.$d, "MM/dd/yyyy")));
              }}
            />
            <Divider plain orientation="left" style={{ color: "darkblue", borderColor: "darkblue" }}>
              Action Types
            </Divider>
            <Row style={{ margin: "0 0 20px 5px", display: "flex", alignItems: "center" }}>
              <div>Select All</div>
              <Switch
                disabled={switchDisabled}
                size="small"
                style={{ marginLeft: "10px", alignItems: "center" }}
                onChange={switchHandler}
              />
            </Row>
            <Checkbox.Group
              disabled={checkboxDisabled}
              style={{ width: "100%" }}
              value={checkedList}
              onChange={checkBoxHandler}
            >
              <Row>
                {coTypes?.data?.map((x) => (
                  <Col key={x} span={12} style={{ marginBottom: "10px" }}>
                    <Checkbox value={x} key={x}>
                      {x}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
            <Divider style={{ borderColor: "darkblue" }} />
            <Button
              type="primary"
              shape="round"
              size="middle"
              loading={coQuery.isLoading || NYSE_coQuery.isLoading || NASDAQ_coQuery.isFetching}
              icon={<SearchOutlined />}
              block
              style={{ marginBottom: "15px" }}
              onClick={buttonHandler}
            >
              Submit
            </Button>
          </div>
          {(coQuery?.data && !NYSE_coQuery?.data && !NASDAQ_coQuery?.data) ||
          (coQuery?.data && !NYSE_coQuery?.data && !NASDAQ_coQuery?.data) ? (
            <div className="block">
              {/* <CloudDownloadOutlined style={{ fontSize: "32px", color: "darkblue" }} />
            <Typography.Title level={3}>DOWNLOAD</Typography.Title> */}
              <Button onClick={downloadHandler} type="link" size="large" icon={<CloudDownloadOutlined />}>
                Download
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CorporateAction;
