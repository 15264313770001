import axios from "axios";
import moment from "moment";

export async function getNYSECorporateAction(queryData) {
  let startDate = moment(queryData["dateFrom"]).format("YYYY-MM-DD");
  let endDate = moment(queryData["dateTo"]).format("YYYY-MM-DD");
  let pageSize = queryData["pageSize"];
  let page = queryData["page"];

  const { data } = await axios.get(
    process.env.REACT_APP_API_PROXY +
      `?action_date__gte=${startDate}&action_date__lte=${endDate}&page=${page}&page_size=${pageSize}`,
    {
      headers: {
        "Target-Endpoint": "https://listingmanager.nyse.com/api/corpax/",
      },
    }
  );
  return data;
}
