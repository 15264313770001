import React from "react";
import { Descriptions, Tooltip } from "antd";
import { RightCircleFilled } from "@ant-design/icons";

const { Item } = Descriptions;

const ORBIS_Tip = ({ data }) => {
  return (
    <div>
      <Tooltip
        placement="topLeft"
        color="white"
        title={
          <Descriptions
            size="middle"
            column={1}
            title="ORBIS Data"
            labelStyle={{ fontWeight: "500" }}
            contentStyle={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Item label="Symbol">
              {data[0].oldEntry.symbol}
              <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
              {data[0].newEntry.symbol}
            </Item>
            <Item label="Cusip">
              {data[0].oldEntry.cusip}
              <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
              {data[0].newEntry.cusip}
            </Item>
            <Item label="Exchange">
              {data[0].oldEntry.exchange}
              <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
              {data[0].newEntry.exchange}
            </Item>

            <Item label="Effect Date">{data[0].effDate.split(" ")[0]}</Item>
            <Item label="Old Name">{data[0].oldEntry.companyName}</Item>
            <Item label="New Name">{data[0].newEntry.companyName}</Item>
            <Item label="Spinoff Ratio">{data[0].spinoffRatio}%</Item>
            <Item label="Split Factor">{data[0].splitFactor}</Item>
            <Item label="Dividend Amount">$USD {data[0].dividendAmount}</Item>
            <Item label="Gross Dividend Amount">$USD {data[0].grossDividendAmount}</Item>
          </Descriptions>
        }
      >
        <a href="#">{data[0].type}</a>
      </Tooltip>
    </div>
  );
};

export default ORBIS_Tip;
