import "./App.css";
import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Row, Col, Button } from "antd";
import { getToken } from "./api/CorporateActionAPI";
import CorporateAction from "./components/CorporateAction";

function App() {
  const c2cToken = useQuery({
    queryKey: ["token"],
    queryFn: getToken,
    // enabled: false,
    staleTime: 10000, // 在這段時間內不做 refresh
  });

  return (
    <div>
      <Row>
        <Col
          xs={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          lg={{ offset: 0, span: 24 }}
          xl={{ offset: 2, span: 20 }}
          xxl={{ offset: 2, span: 20 }}
        >
          <CorporateAction />
        </Col>
      </Row>
    </div>
  );
}

export default App;
