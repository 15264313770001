import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import ORBIS_Tip from "./ORBIS_Tip";

const NASDAQ_CorporateActionTable = ({ ORBIS, NASDAQ }) => {
  const [coData, setCoData] = useState(NASDAQ);
  useEffect(() => {
    const regex = /\/[RWU]/g;
    let res = NASDAQ?.map((x) => ({
      ...x,
      Orbis: ORBIS?.map((y) => y.oldEntry.symbol).includes(x.Symbol.replace(regex, "").split(/[&/]+/)[0]) ? (
        <ORBIS_Tip data={ORBIS?.filter((y) => y.oldEntry.symbol == x.Symbol.replace(regex, "").split(/[&/]+/)[0])} />
      ) : (
        ""
      ),
    }));
    setCoData(res);
  }, [ORBIS]);

  const columns = [
    // {
    //   title: "Alert",
    //   dataIndex: "Alert",
    //   key: "Alert",
    //   align: "center",
    // },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      align: "center",
    },
    {
      title: "Headline",
      dataIndex: "Headline",
      key: "Headline",
      align: "center",
      width: "35%",
    },
    {
      title: "Symbol",
      dataIndex: "Symbol",
      key: "Symbol",
      align: "center",
      width: "20%",
    },
    {
      title: "Link",
      dataIndex: "Link",
      key: "Link",
      align: "center",
      render: (text, record) => (
        <a href={text} target="_blank">
          <Tag color="blue">LINK </Tag>
        </a>
      ),
    },
    {
      title: "Market",
      dataIndex: "Market",
      key: "Market",
      align: "center",
    },
    {
      title: "Orbis",
      dataIndex: "Orbis",
      key: "Orbis",
      align: "center",
    },
  ];

  const dataSource = coData.sort((a, b) => new Date(b.Date) - new Date(a.Date));

  // const regex = /\/[RWU]/g;
  // console.log(
  //   data.map((x) => {
  //     const symbol1 = x.Symbol.replace(regex, "");
  //     const symbol2 = symbol1.map((x) => x.split(""));
  //     return symbol2;
  //   })
  // );

  return (
    <div>
      <Table
        rowKey={(record) => record.Alert}
        size="middle"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          position: ["bottomRight"],
          defaultPageSize: 50,
        }}
      />
    </div>
  );
};

export default NASDAQ_CorporateActionTable;
