import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { getNYSECorporateAction } from "../api/NYSE_CorporateActionAPI";
import { useMutation } from "@tanstack/react-query";
import ORBIS_Tip from "./ORBIS_Tip";

const NYSE_CorporateActionTable = ({ ORBIS, NYSE }) => {
  const [coData, setCoData] = useState(NYSE);

  console.log(ORBIS);

  useEffect(() => {
    if (ORBIS) {
      let res = coData?.results.map((x) => ({
        ...x,
        Orbis: ORBIS?.map((y) => y.oldEntry.symbol).includes(x.issue_symbol.split(" ")[0]) ? (
          <ORBIS_Tip data={ORBIS?.filter((y) => y.oldEntry.symbol == x.issue_symbol.split(" ")[0])} />
        ) : (
          ""
        ),
      }));
      setCoData({ ...coData, results: res });
    }
  }, [ORBIS]);

  const NYSE_coQuery = useMutation({
    mutationFn: getNYSECorporateAction,
    staleTime: 2000,
    onSuccess: (data, variables, context) => {
      if (!variables.symbol) {
        // Filter Orbis data
        let res = data.results.map((x) => ({
          ...x,
          Orbis: ORBIS.map((y) => y.oldEntry.symbol).includes(x.issue_symbol.split(" ")[0]) ? (
            <ORBIS_Tip data={ORBIS?.filter((y) => y.oldEntry.symbol == x.issue_symbol.split(" ")[0])} />
          ) : (
            ""
          ),
        }));

        setCoData({ ...data, results: res });
      } else {
        // Filter Symbols
        let symbolArr = variables.symbol.replace(/\s/g, "").split(","); // symbols need to be filtered
        let filteredData = !variables.symbol
          ? data
          : data.results.filter((obj) => symbolArr.includes(obj.issue_symbol.split(" ")[0])); // Filtered data

        // Filter Orbis data
        let res = filteredData.results.map((x) => ({
          ...x,
          Orbis: ORBIS.map((y) => y.oldEntry.symbol).includes(x.issue_symbol.split(" ")[0]) ? (
            <ORBIS_Tip data={ORBIS?.filter((y) => y.oldEntry.symbol == x.issue_symbol.split(" ")[0])} />
          ) : (
            ""
          ),
        }));

        setCoData({ ...data, results: res }); // set filtered data
      }
    },
  });

  const columns = [
    {
      title: "Action Date",
      dataIndex: "action_date",
      key: "action_date",
      align: "center",
      // render: (text) => <div>{text.split(" ")[0]}</div>,
    },
    {
      title: "Action Status",
      dataIndex: "action_status",
      key: "action_status",
      align: "center",
      render: (text, record) => <div>{text?.length < 30 ? text : text?.substring(0, 30) + "..."}</div>,
    },
    {
      title: "Action Type",
      dataIndex: "action_type",
      key: "action_type",
      align: "center",
    },
    {
      title: "Symbol",
      dataIndex: "issue_symbol",
      key: "issue_symbol",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "issuer_name",
      key: "issuer_name",
      align: "center",
      render: (text, record) => <div>{text?.length < 25 ? text : text?.substring(0, 25) + "..."}</div>,
    },
    {
      title: "Update Date",
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      render: (text) => <div>{text.split("T")[0]}</div>,
    },
    {
      title: "Orbis",
      dataIndex: "Orbis",
      key: "Orbis",
      align: "center",
    },
  ];

  const pageChangeHandler = (pg) => {
    const prev = coData?.previous;
    const next = coData?.next;
    const sUrl = prev != null ? prev : next;
    let url = new URL(sUrl);

    // console.log(url.searchParams.get("action_date__lte"));
    // console.log(pg.current);

    const queryData = {
      symbol: pg.symbol,
      dateFrom: url.searchParams.get("action_date__gte"),
      dateTo: url.searchParams.get("action_date__lte"),
      pageSize: pg.pageSize,
      page: pg.current,
    };
    NYSE_coQuery.mutate(queryData);
  };

  const currentPage = () => {
    if (coData?.previous == null) {
      return 1;
    } else if (coData?.next == null) {
      return Math.ceil(coData?.count / 50);
    } else {
      let { searchParams } = new URL(coData?.previous);
      return parseInt(searchParams.get("page")) + 1;
    }
  };

  // const dataSource = coData?.results.sort((a, b) => new Date(b.action_date) - new Date(a.action_date));
  const dataSource = coData?.results;

  return (
    <div>
      <Table
        rowKey={(record) => record.market_event}
        size="middle"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          position: ["bottomRight"],
          showSizeChanger: false,
          defaultPageSize: 50,
          total: coData?.count,
          current: currentPage(),
        }}
        onChange={(pg) => pageChangeHandler(pg)}
      />
    </div>
  );
};

export default NYSE_CorporateActionTable;
