import axios from "axios";
import jose from "node-jose";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import jwt_decode from "jwt-decode";

export async function getToken() {
  async function fetchToken() {
    const { JWS, JWK } = jose;

    const payload = JSON.stringify({
      group: process.env.REACT_APP_API_GROUP,
      entity: process.env.REACT_APP_API_ENTITY,
      iss: "orbis_c2c_api",
      jti: uuidv4(),
      iat: Math.floor(Date.now() / 1000),
      exp: Math.floor((Date.now() + 60 * 1000) / 1000),
    });

    const key = await JWK.asKey({
      kty: "oct",
      kid: process.env.REACT_APP_API_SECRET,
      k: Buffer.from(process.env.REACT_APP_API_SECRET).toString("base64"),
    });
    const jws = await JWS.createSign(
      { format: "compact" },
      {
        key: key,
        header: {
          alg: "HS256",
        },
      }
    )
      .update(payload)
      .final();

    // Get Token through my api proxy server
    const { data } = await axios.get(process.env.REACT_APP_API_PROXY + "/c2c/jws.action?jws=" + jws, {
      headers: {
        "Target-Endpoint": process.env.REACT_APP_API_HOST,
      },
    });
    localStorage.setItem("C2C", data.token);
    return data.token;
  }

  if (localStorage.getItem("C2C") !== null) {
    if (localStorage.getItem("C2C") === "" || localStorage.getItem("C2C") === "undefined") {
      return await fetchToken(); // 重取 token
    } else if (moment().isAfter(moment.unix(jwt_decode(localStorage.getItem("C2C")).exp))) {
      return await fetchToken(); // 重取 token
    } else {
      return localStorage.getItem("C2C");
    }
  } else {
    return await fetchToken(); // 重取 token
  }
}

export async function getCorporateActionTypes() {
  const { data } = await axios.get(process.env.REACT_APP_API_PROXY + "/api/research/actions/types", {
    headers: {
      "Target-Endpoint": process.env.REACT_APP_API_HOST,
      Authorization: "C2C " + localStorage.getItem("C2C"),
    },
  });
  return data;
}

export async function getCorporateAction(queryData) {
  const { data } = await axios.get(process.env.REACT_APP_API_PROXY + "/api/research/actions/search", {
    params: { ...queryData },
    headers: {
      "Target-Endpoint": process.env.REACT_APP_API_HOST,
      Authorization: "C2C " + localStorage.getItem("C2C"),
    },
  });
  return data;
}
